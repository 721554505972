import React from 'react'
import styles from './stylesMasterplan.module.scss';


export default function ButtonMasterplanItem ( { item, currItem, selectItem } ) {
  
	// Avalon Airport was going to stay on when others in the group were selected, but no need anymore 
	// let btnClassName = '';
  	// if (item.title === currItem) {
    // 	btnClassName = styles.btn__active;
  	// } else if ((item.title === 'Avalon Airport') && (currItem === 'Movement network' || currItem === 'Natural systems & landscape' || currItem === 'Lot density and mix' || currItem === 'Built form')) { //Airport to stay on when others in the group are selected
    // 	btnClassName = styles.btn__active;
  	// } else {
    // 	btnClassName = styles.btn;
  	// }

  	return (
   	 	<button 
      		className={ item.title === currItem ? styles.btn__active : styles.btn } 
      		// className={ btnClassName } 
      		onClick={ () => selectItem(item.title) } 
    	>
      		<img src={ item.iconInactive } className={ styles.inactiveIcon } width='39' height='39' alt={ item.title + ' icon' } />
      		<img src={ item.iconActive } className={ styles.activeIcon } width='39' height='39' alt={ item.title + ' icon' } />
      		{ item.title }
    	</button>
	);
	  
}
