
import MovementInactive from '../../images/masterplan/icon--movement-network-blue.png';
import MovementActive from '../../images/masterplan/icon--movement-network-white.png';
import NaturalInactive from '../../images/masterplan/icon--natural-systems-blue.png';
import NaturalActive from '../../images/masterplan/icon--natural-systems-white.png';
import LotDensityInactive from '../../images/masterplan/icon--lot-density-blue.png';
import LotDensityActive from '../../images/masterplan/icon--lot-density-white.png';
import BuiltFormInactive from '../../images/masterplan/icon--built-form-blue.png';
import BuiltFormActive from '../../images/masterplan/icon--built-form-white.png';
import TransportInactive from '../../images/masterplan/icon--transport-postal-blue.png';
import TransportActive from '../../images/masterplan/icon--transport-postal-white.png';
import ManufacturingInactive from '../../images/masterplan/icon--manufacturing-blue.png';
import ManufacturingActive from '../../images/masterplan/icon--manufacturing-white.png';
import WholesaleInactive from '../../images/masterplan/icon--wholesale-blue.png';
import WholesaleActive from '../../images/masterplan/icon--wholesale-white.png';
import AgricultureInactive from '../../images/masterplan/icon--agriculture-blue.png';
import AgricultureActive from '../../images/masterplan/icon--agriculture-white.png';
import MediaInactive from '../../images/masterplan/icon--information-media-blue.png';
import MediaActive from '../../images/masterplan/icon--information-media-white.png';
import RentalInactive from '../../images/masterplan/icon--rental-hiring-blue.png';
import RentalActive from '../../images/masterplan/icon--rental-hiring-white.png';
import GreenSpaceInactive from '../../images/masterplan/icon--green-space-blue.png';
import GreenSpaceActive from '../../images/masterplan/icon--green-space-white.png';
import RoadInfrastructureInactive from '../../images/masterplan/icon--road-infrastructure-blue.png';
import RoadInfrastructureActive from '../../images/masterplan/icon--road-infrastructure-white.png';
import AvalonAirportInactive from '../../images/masterplan/icon--avalon-airport-blue.png';
import AvalonAirportActive from '../../images/masterplan/icon--avalon-airport-white.png';


export const data = [
    {
        title: 'Urban Structures', 
        groupItems: [
            { 
                title: 'Avalon Airport',
                overlaySrc: 'frg--avalon-airport-masterplan.jpg',
                overlayNode: null, 
                iconInactive: AvalonAirportInactive,
                iconActive: AvalonAirportActive 
            },
            { 
                title: 'Movement network',
                overlaySrc: 'frg--movement-network.jpg',
                overlayNode: null, 
                iconInactive: MovementInactive,
                iconActive: MovementActive
            },
            { 
                title: 'Natural systems & landscape',
                overlaySrc: 'frg--natural-systems-landscape.png',
                overlayNode: null, 
                iconInactive: NaturalInactive,
                iconActive: NaturalActive 
            },
            { 
                title: 'Lot density and mix',
                overlaySrc: 'frg--lot-density-mix.png',
                overlayNode: null, 
                iconInactive: LotDensityInactive,
                iconActive: LotDensityActive 
            },
            { 
                title: 'Built form',
                overlaySrc: 'frg--built-form.png',
                overlayNode: null, 
                iconInactive: BuiltFormInactive,
                iconActive: BuiltFormActive 
            }
        ]
    },
    { 
        title: 'Land Budget', 
        groupItems: [
            { 
                title: 'Transport, postal, warehousing',
                overlaySrc: 'frg--transport-postal-warehousing.png',
                overlayNode: null, 
                iconInactive: TransportInactive,
                iconActive: TransportActive 
            },
            { 
                title: 'Manufacturing',
                overlaySrc: 'frg--manufacturing.png',
                overlayNode: null, 
                iconInactive: ManufacturingInactive,
                iconActive: ManufacturingActive 
            },
            { 
                title: 'Wholesale trade',
                overlaySrc: 'frg--wholesale-trade.png',
                overlayNode: null, 
                iconInactive: WholesaleInactive,
                iconActive: WholesaleActive 
            },
            { 
                title: 'Agriculture, forestry & fishing',
                overlaySrc: 'frg--agriculture-forestry-fishing.png',
                overlayNode: null, 
                iconInactive: AgricultureInactive,
                iconActive: AgricultureActive 
            },
            { 
                title: 'Information Media Technology Park',
                overlaySrc: 'frg--information-media-technology-park.png',
                overlayNode: null, 
                iconInactive: MediaInactive,
                iconActive: MediaActive 
            },
            { 
                title: 'Rental Hiring Real Estate',
                overlaySrc: 'frg--rental-hiring-real-estate.png',
                overlayNode: null, 
                iconInactive: RentalInactive,
                iconActive: RentalActive 
            },
            { 
                title: 'Green space',
                overlaySrc: 'frg--green-space.png',
                overlayNode: null, 
                iconInactive: GreenSpaceInactive,
                iconActive: GreenSpaceActive 
            },
            { 
                title: 'Road infrastructure',
                overlaySrc: 'frg--road-infrastructure.png',
                overlayNode: null , 
                iconInactive: RoadInfrastructureInactive,
                iconActive: RoadInfrastructureActive
            }
        ]
    }
];