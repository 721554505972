
import React, { useState, useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { data } from './dataMasterplan';
import ViewMasterplanBkg from './ViewMasterplanBkg';
import ViewMasterplanOverlay from './ViewMasterplanOverlay';
import ButtonMasterplanItem from './ButtonMasterplanItem';
import styles from './stylesMasterplan.module.scss';

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AccordionContext from 'react-bootstrap/AccordionContext';



function ContextAwareToggle ({ children, eventKey, callback }) {
   
    const currentEventKey = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = currentEventKey === eventKey;
  
    return (
        <button
            className={ isCurrentEventKey ? styles.accordionHeaderBtn__active : styles.accordionHeaderBtn  }
            onClick={ decoratedOnClick }
        >
            <div className={ styles.icon }></div>
            { children }
        </button>
    );

}




export default function ViewMasterplan () {

    const [ currItem, setCurrItem ] = useState('');
    const onItemSected = (item) => {
        const newItem = item;
        setCurrItem(newItem);
    }

    const overlaysQuery = useStaticQuery(graphql`
	{
		allFile(filter: {relativeDirectory: {eq: "masterplan"}, base: {regex: "/frg/"}}) {
			edges {
				node {
					id
					base
					childImageSharp {
						fixed(width: 1920, height: 1080, quality: 80) {
                            ...GatsbyImageSharpFixed_noBase64
                        }
					}
				}
			}
		}
	}
    `);
    
    const getImgNodes = () => { //use the retrieved file names to match the data up and assign the nodes for Gatsby IMG
        const imagesList = overlaysQuery.allFile.edges; //assign the node which matches the src within the data
        data.forEach( item => {
            item.groupItems.forEach( itemInGroup => {
                const matchedImgNode = imagesList.find( img => img.node.base === itemInGroup.overlaySrc ); 
                itemInGroup.overlayNode = matchedImgNode.node;
            });
        });
    }
    getImgNodes();

    const getOverlays = () => {
        const overlays = [];
        data.forEach( item => {
            item.groupItems.forEach( itemInGroup => {
                overlays.push( <ViewMasterplanOverlay key={ itemInGroup.title } item={ itemInGroup } currItem={ currItem } /> );
            });
        });
        return overlays;
    }

    const getGroupButtons = (group) => {
        const groupButtons = group.map( item => 
            <li key={ item.title }>
                <ButtonMasterplanItem item={ item } currItem={ currItem } selectItem={ onItemSected } />
            </li>
        );
        return groupButtons;
    }

    const getAccordion = () => {
        const accordionItems = [];
        let index = 0;
        data.forEach( (item) => {
            accordionItems.push(
                <Card key={ index } className={ styles.accordionCard }>
                    <Card.Header className={ styles.accordionCardHeader }>
                        <ContextAwareToggle eventKey={ index.toString() }>{ item.title }</ContextAwareToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={ index.toString() } className={ styles.accordionCollapse }>
                        <Card.Body className={ styles.accordionCardBody }>
                            <ul>
                                { getGroupButtons(item.groupItems) }
                            </ul>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            );
            index++;
        });
        return accordionItems;
    }
    
    return (
        <>
           <ViewMasterplanBkg />
           <div className={ styles.frg }>
             { getOverlays() }
           </div>
           <nav className={ styles.menu }>
                <h2 className={ styles.title }>Masterplan</h2>
                <Accordion defaultActiveKey='0' className={ styles.accordion }>
                    { getAccordion() }
                </Accordion>
            </nav>
        </>
    );

}