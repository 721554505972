import React, { useRef, useEffect } from 'react';
import Img from 'gatsby-image';
import { utilAnimate } from '../../utils/utilAnimate';
import styles from './stylesMasterplan.module.scss';


export default function ViewMasterplanOverlay ( { item, currItem } ) {

  	const containerRef = useRef(null);
	useEffect(() => {
		if (item.title === 'Avalon Airport') { //ensure it sits above Movement network, which is a jpg instead of a transparent png
			containerRef.current.style.zIndex = 1;
		}
		// Avalon Airport was going to stay on when others in the group were selected, but no need anymore 
		// if (item.title === currItem) {
		// 	utilAnimate.fadeIn(containerRef.current);
		// } else if ((item.title === 'Avalon Airport') && (currItem === 'Movement network' || currItem === 'Natural systems & landscape' || currItem === 'Lot density and mix' || currItem === 'Built form')) { //Airport to stay on when others in the group are selected
		// 	utilAnimate.fadeIn(containerRef.current);
		// } else {
		// 	utilAnimate.fadeOut(containerRef.current);
		// }
		item.title === currItem ? utilAnimate.fadeIn(containerRef.current) : utilAnimate.fadeOut(containerRef.current);
	});

  	return (
    	<div ref={ containerRef } className={ styles.overlay }>
        	<Img fixed={ item.overlayNode.childImageSharp.fixed } key={ item.overlayNode.id } alt={ item.title } />
    	</div>
  	);

}
