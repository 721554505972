import React from 'react';
import SEO from '../components/seo/SEO';
import ViewMasterplan from '../components/masterplan/ViewMasterplan';


export default function Masterplan () { 

  return (
    <>
      <SEO title='Masterplan' />
      <ViewMasterplan />
    </>
  );

}
